import merge from 'lodash.merge';
// env variables are set as String
const stringToBoolean = (value, def) => (value ? value === 'true' : def);

const common = {
  MAX_ATTACHMENT_SIZE: 5000000,
  rum: {
    version: '0.1.0.d0689af4', // metadata env variable replaced by sed on build
    applicationName: 'boss-admin',
    beaconUrl: 'https://rum-ingest.us1.signalfx.com/v1/rum',
    rumAccessToken: 'rrUwCjYZvdjw07-NE-zKMQ',
  },
};

const local = {
  appType: 'bots', // metadata env variable replaced by sed on build
  rum: {
    deploymentEnvironment: 'local',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://2idxhrkpmb.execute-api.us-east-1.amazonaws.com/develop',
  },
  cognito: {
    SAML_LOGIN: false,
    MANDATORY_SIGN_IN: true,
    REGION: 'us-east-1',
    saml: {
      IDP_NAME: 'CustomerIdentity',
      IDP_RESPONSE_TYPE: 'code',
      IDP_LOGIN_URL: 'https://boss-api-develop.auth.us-east-1.amazoncognito.com/oauth2/authorize',
      IDP_SCOPE: 'phone+email+profile+openid',
      DOMAIN: 'boss-api-develop.auth.us-east-1.amazoncognito.com',
      USER_POOL_ID: 'us-east-1_rh3cfaJbC',
      APP_CLIENT_ID: '3hqhb85a85kbsc3l8idj0o26r',
      IDENTITY_POOL_ID: 'us-east-1:743db18d-2c5e-4323-a63f-1abfefa88c12',
      SCOPE: ['phone', 'email', 'profile', 'openid'],
      REDIRECT_SIGN_IN: 'http://localhost:3000/successLogin',
      REDIRECT_SIGN_OUT: 'http://localhost:3000/logout',
    },
    USER_POOL_ID: 'us-east-1_shYX7ir2r',
    APP_CLIENT_ID: '4eucr0h58r0j822or0ms18rai0',
    IDENTITY_POOL_ID: 'us-east-1:0e4bf22f-2058-4182-91cc-4b4e1cca2704',
  },
};

const dev = {
  appType: 'bots', // metadata env variable replaced by sed on build
  rum: {
    deploymentEnvironment: 'development',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://2idxhrkpmb.execute-api.us-east-1.amazonaws.com/develop',
  },
  cognito: {
    SAML_LOGIN: stringToBoolean(process.env.REACT_APP_SAML_LOGIN, false),
    MANDATORY_SIGN_IN: true,
    REGION: 'us-east-1',
    saml: {
      IDP_NAME: 'CustomerIdentity',
      IDP_RESPONSE_TYPE: 'code',
      IDP_LOGIN_URL: 'https://boss-api-develop.auth.us-east-1.amazoncognito.com/oauth2/authorize',
      IDP_SCOPE: 'phone+email+profile+openid',
      DOMAIN: 'boss-api-develop.auth.us-east-1.amazoncognito.com',
      USER_POOL_ID: 'us-east-1_rh3cfaJbC',
      APP_CLIENT_ID: '3hqhb85a85kbsc3l8idj0o26r',
      IDENTITY_POOL_ID: 'us-east-1:743db18d-2c5e-4323-a63f-1abfefa88c12',
      SCOPE: ['phone', 'email', 'profile', 'openid'],
      REDIRECT_SIGN_IN: 'http://localhost:3000/successLogin',
      REDIRECT_SIGN_OUT: 'http://localhost:3000/logout',
    },
    USER_POOL_ID: 'us-east-1_shYX7ir2r',
    APP_CLIENT_ID: '4eucr0h58r0j822or0ms18rai0',
    IDENTITY_POOL_ID: 'us-east-1:0e4bf22f-2058-4182-91cc-4b4e1cca2704',
  },
};

const staging = {
  appType: 'bots', // metadata env variable replaced by sed on build
  rum: {
    deploymentEnvironment: 'staging',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://kmj2q119td.execute-api.us-east-1.amazonaws.com/staging',
  },
  cognito: {
    SAML_LOGIN: stringToBoolean(process.env.REACT_APP_SAML_LOGIN, false),
    MANDATORY_SIGN_IN: true,
    REGION: 'us-east-1',
    saml: {
      IDP_NAME: 'CustomerIdentity',
      IDP_RESPONSE_TYPE: 'code',
      IDP_LOGIN_URL: 'https://boss-staging.auth.us-east-1.amazoncognito.com/oauth2/authorize',
      IDP_SCOPE: 'phone+email+profile+openid',
      DOMAIN: 'boss-staging.auth.us-east-1.amazoncognito.com',
      USER_POOL_ID: 'us-east-1_0WkinfHRM',
      APP_CLIENT_ID: '3p4g690821jo030d8tbe5eon9t',
      IDENTITY_POOL_ID: 'us-east-1:b86cf4f8-cc9e-4289-b23d-568483a565af',
      SCOPE: ['phone', 'email', 'profile', 'openid'],
      REDIRECT_SIGN_IN: `https://${window.location.hostname}/successLogin`,
      REDIRECT_SIGN_OUT: `https://${window.location.hostname}/logout`,
    },
    USER_POOL_ID: 'us-east-1_ITabwycJx',
    APP_CLIENT_ID: '1ferfi9h7roogvdgcjrrd6glv5',
    IDENTITY_POOL_ID: 'us-east-1:d4d93b6b-c9d0-4519-99a8-1876e2821e82',
  },
};

const qa = {
  ...staging,
  cognito: {
    ...staging.cognito,
    SAML_LOGIN: stringToBoolean(process.env.REACT_APP_SAML_LOGIN, false),
  },
};

const prod = {
  appType: 'bots', // metadata env variable replaced by sed on build
  rum: {
    deploymentEnvironment: 'production',
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://bxqghv1wo2.execute-api.us-east-1.amazonaws.com/prod',
  },
  cognito: {
    SAML_LOGIN: stringToBoolean(process.env.REACT_APP_SAML_LOGIN, false),
    MANDATORY_SIGN_IN: true,
    REGION: 'us-east-1',
    saml: {
      IDP_NAME: 'CustomerIdentity',
      IDP_RESPONSE_TYPE: 'code',
      IDP_LOGIN_URL: 'https://boss-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize',
      IDP_SCOPE: 'phone+email+profile+openid',
      DOMAIN: 'boss-prod.auth.us-east-1.amazoncognito.com',
      USER_POOL_ID: 'us-east-1_UWJXZZ769',
      APP_CLIENT_ID: '3qrbmuielqsdls85jojnvjpp4p',
      IDENTITY_POOL_ID: 'us-east-1:ae09d2ef-1a1a-4dd3-9918-9e2decabe130',
      SCOPE: ['phone', 'email', 'profile', 'openid'],
      REDIRECT_SIGN_IN: `https://${window.location.hostname}/successLogin`,
      REDIRECT_SIGN_OUT: `https://${window.location.hostname}/logout`,
    },
    USER_POOL_ID: 'us-east-1_UOCAR2JT0',
    APP_CLIENT_ID: '1hsik0vuvsusg9iunipekheekq',
    IDENTITY_POOL_ID: 'us-east-1:9a4a7263-d922-4c96-a115-d5213d381ad5',
  },
};
let envConfig;

// Default to dev if not set
if (process.env.REACT_APP_STAGE === 'prod') {
  envConfig = prod;
} else if (process.env.REACT_APP_STAGE === 'staging') {
  envConfig = staging;
} else if (process.env.REACT_APP_STAGE === 'qa') {
  envConfig = qa;
} else if (process.env.REACT_APP_STAGE === 'dev') {
  envConfig = dev;
} else {
  envConfig = local;
}

export default merge(common, envConfig);
